import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { Route } from 'react-router-dom';
import { COHERE_CONFIG, COOKIES_CONFIG, useWindowSize, BASE_PATHS, FEATURE_FLAGS } from '@belong/common';
import { BREAKPOINTS_WIDTHS } from '@belong/common/dist/constants/breakpoints';
import { Analytics, PageViewTracker } from 'analytics';
import classNames from 'classnames/bind';
import { ChatWidget } from 'components/ChatWidget/ChatWidget';
import { RouterHeaderNavLink } from 'components/HeaderMain/RouterHeaderNavLink/RouterHeaderNavLink';
import MetaNoIndex from 'components/Metatags/MetaNoIndex';
import Spinner from 'components/Spinner/Spinner';
import { SCREENS } from 'containercomponents/Modals/LoginModal/login-modal.consts';
import { MODALS } from 'containercomponents/Modals/modal.consts';
import { ContactUs } from 'containers/ContactUs/ContactUs';
import { startCase } from 'es-toolkit';
import { useModal } from 'hooks/useModal';
import { FlowLayoutV2 } from 'layouts/FlowLayout/FlowLayoutV2/FlowLayoutV2';
import {
  compact,
  every,
  filter,
  find,
  findIndex,
  flatten,
  groupBy,
  includes,
  some,
  uniq,
  isNil,
  isEmpty,
  last,
} from 'lodash-es';
import { FlowStepDataType } from 'models/enums';
import { destroyCookie } from 'nookies';
import { getSaveAndExitPathFromOnboardingProperties } from 'pages/HomeOwnerOnboarding/utils';
import PropTypes from 'prop-types';
import queryString from 'query-string';
import { compose } from 'redux';
import {
  fetchOnboardingProperties,
  fetchSignupFlow,
  fetchSignupFlowStepData,
  updateSignupFlowStepData,
  createHomeownerLeads,
  fetchIsSelfServeEligible,
} from 'store/redux/homeowner-onboarding/actions';
import { selectAddresses } from 'store/redux/homeowner-onboarding/selectors';
import { showModal } from 'store/redux/modals/actions';
import { fetchRegions } from 'store/redux/settings';
import { selectIsUserLoggedIn, selectUser } from 'store/redux/user/selectors';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { trackFullStoryEvent } from 'utils/tracking';
import { getDefaultHomeownerGrowth } from '../../consts/employee-assignments';
import styles from './HomeOwnerOnboarding.module.css';
import ReferralMessage from './ReferralMessage/ReferralMessage';
import SelfServeModal from './Steps/MaintenanceInvolvement/SelfServeModal';
import { HomeownerOnboardingRoutes } from './Steps/routes';
import {
  getHomeownerOnboardingStepPath,
  getSignUpFlowRoute,
  sortStepsByType,
  STEPS,
  STEPS_CONFIG,
} from './Steps/steps';
import { SCHEDULE_STEP_ITEM, STEPS_LABEL } from './constants';

const { REFERRAL_USER, REFERRAL_ID } = COOKIES_CONFIG;

const cx = classNames.bind(styles);

const getCurrentStep = (pathname) => {
  const foundByKeyAndGroup = find(STEPS, (step) => includes(pathname, step.key) && includes(pathname, step.group));
  if (!isNil(foundByKeyAndGroup)) {
    return foundByKeyAndGroup;
  }

  return find(STEPS, (step) => includes(pathname, step.key));
};

const getGroupsAndRoutes = (properties = [], flow = { steps: [] }) => {
  properties = properties.filter((property) => property?.basicInfo?.inCoverageArea);
  const activeSteps = flow.steps?.filter((step) => !step.isHidden);
  const sortedSteps = sortStepsByType(activeSteps);
  const groupedStepsByDataType = groupBy(sortedSteps, 'dataType');
  const homesInTheFlow = uniq(groupedStepsByDataType[FlowStepDataType.Home]?.map((h) => h.dataId));

  const userGroup = {
    steps: groupedStepsByDataType[FlowStepDataType.User] || [],
  };

  const propertiesGroup = properties?.map((property, pIndex) => {
    const { propertyId } = property.basicInfo;
    return compact(
      property.units?.map((home, index) => {
        const homeId = home.basicInfo.unitId;

        if (!includes(homesInTheFlow, homeId)) {
          return null;
        }

        const isLastProperty = index === property.units.length - 1;

        const steps = [
          ...(index === 0 ? filter(sortedSteps, { dataId: propertyId }) : []),
          ...filter(sortedSteps, { dataId: homeId }),
        ];

        const routingSteps = [
          ...(properties.length > 1 || property.units.length > 1 ? [STEPS_CONFIG.MULTIPLE_PROPERTIES] : []),
          ...steps,
          ...(isLastProperty && !isEmpty(userGroup?.steps) ? userGroup.steps : []),
        ]?.map((step) => ({ ...step, homeId }));

        const completed = every(steps, { status: 'Completed' });

        return {
          home,
          property,
          steps,
          routingSteps,
          completed,
          homeIndex: index,
          propertyIndex: pIndex,
        };
      })
    );
  });

  const homesGroup = flatten(propertiesGroup);
  const routingStepsArray =
    homesGroup.length === 1
      ? homesGroup[0].routingSteps
      : [...flatten(homesGroup?.map((homeGroup) => homeGroup.routingSteps))];

  const group = {
    homes: homesGroup,
    user: userGroup,
  };

  const prequalificationStep = routingStepsArray.find((step) => step.stepName === 'PreQualification');

  const routingStepsArrayWithoutPreQualification = routingStepsArray.filter(
    (step) => step.stepName !== 'PreQualification'
  );

  return [group, routingStepsArrayWithoutPreQualification, prequalificationStep];
};

const HomeOwnerOnboarding = ({
  history,
  isUserLoggedIn,
  user,
  fetchOnboardingProperties: fetchOnboardingPropertiesAction,
  match: { params },
  location,
  location: { pathname, search },
  showModal: showModalAction,
  createHomeownerLeads: createHomeownerLeadsAction,
  fetchSignupFlowStepDataAction,
  updateSignupFlowStepDataAction,
  fetchRegionsAction,
  fetchIsSelfServeEligibleAction,
  addresses,
}) => {
  const [onboardingPropertiesData, setOnboardingPropertiesData] = useState(null);
  const [currentStepData, setCurrentStepData] = useState({});
  const [signUpFlow, setSignupFlow] = useState({});
  const [groups, setGroups] = useState({});
  const [routingSteps, setRoutingSteps] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isStepLoading, setIsStepLoading] = useState(false);
  const [isSelfServeModalOpen, setIsSelfServeModalOpen, setIsSelfServeModalClose] = useModal();
  const { schedule, isEnabled } = COHERE_CONFIG.homeowner;
  const { group, stepId } = params;
  const currentStep = getCurrentStep(pathname);
  const { width } = useWindowSize();

  const isMobile = width <= BREAKPOINTS_WIDTHS.MD;

  const currentStepInSignUpFlow = signUpFlow?.steps?.find((step) => step?.id === stepId);

  const { isPreQualified, wantsToBeCalled } = currentStepData?.preQualification?.preQualificationResult || {};

  const handleTalkToSomeone = () => {
    setIsSelfServeModalClose();
    history.push(STEPS_CONFIG.SCHEDULE_CHAT.path);
  };

  const fullStoryEventMetadata = {
    flowType: 'Setup',
    flowSubType: 'Onboarding',
    stepEnum: currentStepInSignUpFlow?.stepName,
    stepDisplayName: STEPS_LABEL[currentStepInSignUpFlow?.stepName],
  };

  const handleSave = async (values) => {
    const step = find(signUpFlow.steps, { id: stepId });
    await updateSignupFlowStepDataAction(signUpFlow.id, step, values);
  };

  const handleEnterFlow = () => {
    setIsLoading(true);
    const stepRoute = getSignUpFlowRoute(routingSteps[0]);

    history.push(stepRoute);
  };

  const handleComplete = async () => {
    setIsLoading(true);

    const [data, isSelfServeEligible] = await Promise.all([
      fetchOnboardingPropertiesAction(),
      fetchIsSelfServeEligibleAction({ flowId: signUpFlow.id }),
    ]);

    destroyCookie(null, REFERRAL_USER.name);
    destroyCookie(null, REFERRAL_ID.name);

    setOnboardingPropertiesData(data.properties);

    setIsLoading(false);

    if (isSelfServeEligible) {
      setIsSelfServeModalOpen();
    } else if (isPreQualified && wantsToBeCalled) {
      history.push(STEPS_CONFIG.SUCCESS_PAGE.path);
    } else {
      handleTalkToSomeone();
    }

    window.localStorage.removeItem('address-from-onboarding');
  };

  const handleNext = async (values) => {
    if (currentStep.key === STEPS_CONFIG.PROPERTY_ADDRESS.key) {
      const [groupData, routingStepsArray] = getGroupsAndRoutes(values.properties, values.signUpFlow);

      setOnboardingPropertiesData(values.properties);
      setRoutingSteps(routingStepsArray);
      setSignupFlow(values.signUpFlow);
      setGroups(groupData);

      if (
        FEATURE_FLAGS.HOMEOWNER_ONBOARDING_PRE_QUALIFICATION &&
        values.properties?.length === 1 &&
        values.properties?.[0]?.units?.length === 1 &&
        values.properties?.[0]?.units?.[0]?.basicInfo?.unitId
      ) {
        history.push(`/homeowners/sign-up/${values.properties[0].units[0].basicInfo.unitId}`);

        return;
      }

      history.push(getSignUpFlowRoute(routingStepsArray[0]));

      return;
    }

    if (currentStep.key === STEPS_CONFIG.SCHEDULE_CHAT.key) {
      history.push(STEPS_CONFIG.SUCCESS_PAGE.path);
      return;
    }

    const stepIndex = findIndex(routingSteps, { id: stepId });
    if (stepIndex !== routingSteps.length - 1) {
      const nextStep = routingSteps[stepIndex + 1];

      trackFullStoryEvent({
        eventName: 'flow-step-completed',
        metadata: fullStoryEventMetadata,
      });

      setCurrentStepData({});
      history.push(getSignUpFlowRoute(nextStep));
    } else {
      trackFullStoryEvent({
        eventName: 'flow-completed',
        metadata: {
          ...fullStoryEventMetadata,
          stepEnum: 'success',
          stepDisplayName: 'Success',
        },
      });

      await handleComplete();
    }
  };

  const handleSaveAndNext = async (values) => {
    setIsLoading(true);

    try {
      await handleSave(values);
    } catch (e) {
      trackFullStoryEvent({
        eventName: 'flow-error',
        metadata: fullStoryEventMetadata,
      });
      console.error(e);
    }

    await handleNext(values);
  };

  const handleBack = () => {
    const stepIndex = findIndex(routingSteps, { id: stepId });

    if (stepIndex === 0 || group === 'dashboard') {
      history.push(getHomeownerOnboardingStepPath(STEPS_CONFIG.PROPERTY_ADDRESS, {}, search));
    } else if (stepIndex !== 0) {
      setIsLoading(true);

      const nextStep = routingSteps[stepIndex - 1];
      setCurrentStepData({});
      history.push(getSignUpFlowRoute(nextStep));
    }
  };

  // Initial use effect
  useEffect(() => {
    (async () => {
      const queryParams = queryString.parse(search) || {};
      const { mode, ...extraQueryParams } = queryParams;

      if (isUserLoggedIn && !onboardingPropertiesData) {
        setIsLoading(true);

        await fetchRegionsAction();
        const { properties, signUpFlow: flow } = await fetchOnboardingPropertiesAction();
        const [groupData, routingStepsArray, preQualificationStep] = flow
          ? getGroupsAndRoutes(properties, flow)
          : [{}, []];

        setOnboardingPropertiesData(properties);
        setRoutingSteps(routingStepsArray);
        setSignupFlow(flow);
        setGroups(groupData);

        if (mode === 'resume') {
          if (flow && routingStepsArray.length) {
            const hasMultipleProperties = routingStepsArray.some((step) => step.name === 'multiple_properties');

            if (
              FEATURE_FLAGS.HOMEOWNER_ONBOARDING_PRE_QUALIFICATION &&
              !hasMultipleProperties &&
              preQualificationStep?.status === 'Created'
            ) {
              history.replace(`/homeowners/sign-up/${preQualificationStep.dataId}/pre-qualification`);

              return;
            }

            const hasExtraQueryParams = Object.keys(extraQueryParams).length > 0;

            return history.replace({
              pathname: getSignUpFlowRoute(routingStepsArray?.[0]),
              search: hasExtraQueryParams ? queryString.stringify(extraQueryParams) : undefined,
            });
          }

          return history.replace(getHomeownerOnboardingStepPath(STEPS_CONFIG.PROPERTY_ADDRESS, {}, search));
        }
      }
    })();
  }, []);

  // useEfect for tracking FullStory events.
  useEffect(() => {
    if (!currentStepInSignUpFlow || !signUpFlow) return;

    const isCurrentStepCompleted = currentStepInSignUpFlow.status === 'Completed';
    const firstStepInSignUpFlow = signUpFlow.steps.find((s) => s.isHidden === false);
    const isCurrentStepTheFirstOne = currentStepInSignUpFlow.id === firstStepInSignUpFlow.id;

    if (isCurrentStepTheFirstOne && !isCurrentStepCompleted) {
      return trackFullStoryEvent({
        eventName: 'flow-started',
        metadata: fullStoryEventMetadata,
      });
    }

    if (!isCurrentStepCompleted) {
      trackFullStoryEvent({
        eventName: 'flow-step-started',
        metadata: fullStoryEventMetadata,
      });
    }
  }, [currentStepInSignUpFlow, signUpFlow]);

  // Dashboard use effect
  useEffect(() => {
    if (group === 'dashboard') {
      (async () => {
        const { properties, signUpFlow: flow } = await fetchOnboardingPropertiesAction();
        const [groupData, routingStepsArray] = flow ? getGroupsAndRoutes(properties, flow) : [{}, []];

        setOnboardingPropertiesData(properties);
        setRoutingSteps(routingStepsArray);
        setSignupFlow(flow);
        setGroups(groupData);
        setIsLoading(false);
      })();
    }
  }, [group]);

  // Step use effect
  useEffect(() => {
    if (!isUserLoggedIn || !stepId) return setIsLoading(false);

    (async () => {
      const { properties, signUpFlow: flow } = await fetchOnboardingPropertiesAction();
      const [groupData, routingStepsArray] = flow ? getGroupsAndRoutes(properties, flow) : [{}, []];

      const step = find(flow?.steps, { id: stepId });

      // This condition is only falsy when there is no longer an active flow
      // A potential solution would be to filter steps with repeated ids on the handle next
      if (!step) return handleComplete();

      const stepData = await fetchSignupFlowStepDataAction(flow.id, step);

      setOnboardingPropertiesData(properties);
      setRoutingSteps(routingStepsArray);
      setSignupFlow(flow);
      setGroups(groupData);
      setCurrentStepData(stepData);
      setIsLoading(false);
    })();
  }, [stepId]);

  useEffect(() => {
    const [firstAddress] = addresses || [];

    if (firstAddress && firstAddress.latitude) {
      window.localStorage.setItem(
        'address-from-onboarding',
        JSON.stringify({
          ...firstAddress,
          coordinates: {
            latitude: firstAddress.latitude,
            longitude: firstAddress.longitude,
          },
        })
      );
    }
  }, []);

  const handleSaveAndExit = () => {
    trackFullStoryEvent({
      eventName: 'flow-exited',
      metadata: fullStoryEventMetadata,
    });

    destroyCookie(null, REFERRAL_USER.name, { path: '/' });
    destroyCookie(null, REFERRAL_ID.name, { path: '/' });

    const saveAndExitPath = getSaveAndExitPathFromOnboardingProperties({ onboardingPropertiesData });

    if (!isUserLoggedIn) {
      showModalAction(MODALS.LOGIN, {
        currentScreen: SCREENS.ENTER_USER_TO_SAVE_SCREEN,
        screenProps: {
          returnUserToComponent: (userInfo) => {
            // This is probably no longer needed
            createHomeownerLeadsAction(userInfo);

            history.push(saveAndExitPath);
          },
        },
      });
    } else if (user.userMustSetPassword) {
      showModalAction(MODALS.LOGIN, {
        user,
        currentScreen: SCREENS.SET_PASSWORD_AFTER_AGREEMENT,
        closeButton: false,
        closable: false,
        isNewUser: false,
        onSucessfulLogin: async () => {
          history.push(saveAndExitPath);
        },
      });
    } else {
      history.push(saveAndExitPath);
    }
  };

  if (pathname === BASE_PATHS.HOMEOWNER_ONBOARDING) {
    return null;
  }

  if (isUserLoggedIn && pathname === BASE_PATHS.HOMEOWNER_ONBOARDING && !onboardingPropertiesData) {
    return (
      <div className={cx('full-page-spinner')}>
        <Spinner />
      </div>
    );
  }

  if (pathname === STEPS_CONFIG.SUCCESS_PAGE.path) {
    const success = HomeownerOnboardingRoutes[HomeownerOnboardingRoutes.length - 1];
    return <Route key={success.path} path={success.path} component={success.component} />;
  }

  const [property] = onboardingPropertiesData || [];
  const employeeAssignment = getDefaultHomeownerGrowth(property?.basicInfo?.coverageRegionId);
  const { state: { step = 0 } = {} } = location;
  const currentStepResources = { user };

  if (stepId) {
    const homeGroup = find(groups.homes, (homeGroupData) => {
      return some(homeGroupData.routingSteps, { id: stepId });
    });

    if (homeGroup) {
      currentStepResources.property = homeGroup.property;
      currentStepResources.home = homeGroup.home;
      currentStepResources.routingSteps = homeGroup.routingSteps;
      currentStepResources.isLastHome = groups.homes.indexOf(homeGroup) === groups.homes.length - 1;
    }
  } else if (currentStep.key === 'schedule-chat') {
    currentStepResources.routingSteps = last(groups.homes)?.routingSteps;
    currentStepResources.isLastHome = true;
  }

  const progressItems = [
    ...(!isEmpty(currentStepResources.routingSteps) ? filter(currentStepResources.routingSteps, 'stepName') : []),
    ...(currentStepResources.isLastHome ? [SCHEDULE_STEP_ITEM] : []),
  ]
    .map(({ stepName }) => {
      const label = !isEmpty(STEPS_LABEL[stepName]) ? STEPS_LABEL[stepName] : startCase(stepName);

      return {
        active: stepName === currentStep?.name,
        stepName,
        label,
      };
    })
    .filter(({ stepName }) => stepName !== 'PreQualification');

  const showProgressBar =
    !isEmpty(progressItems) &&
    !isNil(currentStep) &&
    !isNil(progressItems.find(({ stepName }) => stepName === currentStep.name));

  const activeHomeownerOnboardingRoutes = HomeownerOnboardingRoutes.filter((route) => {
    const activeStep = signUpFlow?.steps?.find(
      (signUpStep) => signUpStep.stepName === route.name && signUpStep.isHidden
    );

    return !activeStep;
  });

  return (
    <>
      <MetaNoIndex />
      <ChatWidget
        tags={['Page: Homeowner onboarding']}
        schedule={schedule}
        shouldShow
        isEnabled={isEnabled}
        className=""
        userData={user ? { name: `${user.firstName} ${user.lastName}`, email: user.email } : null}
        showOnNavbar={isMobile}
      />
      <FlowLayoutV2
        location={location}
        loading={isStepLoading || isLoading}
        sidebarProps={{ step }}
        navigationComponents={[
          <ContactUs key="ContactUs" />,
          <RouterHeaderNavLink key="save" label="SAVE & EXIT" onClick={handleSaveAndExit} />,
        ]}
        currentStep={currentStep}
        tip={HOMEOWNER_ONBOARDING_STRINGS[currentStep.name].tip}
        steps={activeHomeownerOnboardingRoutes}
        progressItems={progressItems}
        showProgressBar={showProgressBar}
        employeeAssignment={employeeAssignment}
        stepProps={{
          showSpinner: () => setIsStepLoading(true),
          hideSpinner: () => setIsStepLoading(false),
          // MARK FOR DELETION START
          onSave: handleSave,
          onNext: handleNext,
          // MARK FOR DELETION END
          onSaveAndNext: handleSaveAndNext,
          onBack: handleBack,
          onEnterFlow: handleEnterFlow,
          onComplete: handleComplete,
          groups,
          properties: onboardingPropertiesData,
          currentStepResources,
          currentStepData,
          signUpFlow,
        }}
      />
      {!!isSelfServeModalOpen && (
        <SelfServeModal
          show={isSelfServeModalOpen}
          onTalkToSomeone={handleTalkToSomeone}
          onboardingProperties={onboardingPropertiesData}
          user={user}
          signUpFlow={signUpFlow}
          stepId={stepId}
          currentStepData={currentStepData}
        />
      )}
      <ReferralMessage />
    </>
  );
};

HomeOwnerOnboarding.propTypes = {
  history: PropTypes.shape({
    replace: PropTypes.func,
    push: PropTypes.func,
  }).isRequired,
  match: PropTypes.shape({
    params: PropTypes.object,
  }).isRequired,
  user: PropTypes.object.isRequired,
  location: PropTypes.object.isRequired,
  showModal: PropTypes.func.isRequired,
  createHomeownerLeads: PropTypes.func.isRequired,
  isUserLoggedIn: PropTypes.bool.isRequired,
  fetchOnboardingProperties: PropTypes.func.isRequired,
  fetchSignupFlowStepDataAction: PropTypes.func.isRequired,
  updateSignupFlowStepDataAction: PropTypes.func.isRequired,
  fetchRegionsAction: PropTypes.func.isRequired,
  fetchIsSelfServeEligibleAction: PropTypes.func.isRequired,
  addresses: PropTypes.array.isRequired,
};

export default compose(
  connect(
    (state) => ({
      isUserLoggedIn: selectIsUserLoggedIn(state),
      user: selectUser(state),
      addresses: selectAddresses(state),
    }),
    {
      showModal,
      fetchOnboardingProperties,
      createHomeownerLeads,
      fetchSignupFlowAction: fetchSignupFlow,
      fetchSignupFlowStepDataAction: fetchSignupFlowStepData,
      updateSignupFlowStepDataAction: updateSignupFlowStepData,
      fetchRegionsAction: fetchRegions,
      fetchIsSelfServeEligibleAction: fetchIsSelfServeEligible,
    }
  ),
  Analytics(({ location }) => ({
    screen: location.pathname.split('/').pop(),
  })),
  PageViewTracker
)(HomeOwnerOnboarding);
