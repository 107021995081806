import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { BASE_PATHS } from '@belong/common';
import { Confetti } from 'animations/Confetti/Confetti';
import classNames from 'classnames/bind';
import Button, { BUTTON_SIZES, BUTTON_TYPES } from 'components/Button/Button';
import ModalV2, { MODAL_TYPES } from 'components/Modal/ModalV2/ModalV2';
import TextBubbleWithUserProfile from 'components/TextBubbleWithUserProfile/TextBubbleWithUserProfile';
import { getDefaultHomeownerGrowth } from 'consts/employee-assignments';
import { AgreementFlowStepNames } from 'models/enums';
import { getSelfServeModalTitle } from 'pages/HomeOwnerOnboarding/utils';
import PropTypes from 'prop-types';
import { generateAgreement } from 'store/redux/agreement-flow/actions';
import { trackSelfServeDecisionTaken } from 'store/redux/tracking/actions';
import { EMPLOYEE_TITLES } from 'strings/common.strings';
import { HOMEOWNER_ONBOARDING_STRINGS } from 'strings/homeowner-onboarding.strings';
import { trackFullStoryEvent } from 'utils/tracking';
import { ANALYTICS_CLICK_ACTIONS, ANALYTICS_MODAL_NAMES } from '../../../../analytics';
import styles from './SelfServeModal.module.css';

const cx = classNames.bind(styles);

const SelfServeModal = ({ onboardingProperties, onTalkToSomeone, user, signUpFlow, currentStepData, ...rest }) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const [property] = onboardingProperties;
  const employeeAssignment = getDefaultHomeownerGrowth(property?.basicInfo?.coverageRegionId);

  const employee = {
    ...employeeAssignment.employee,
    ...employeeAssignment.employeeAssignment,
    ...employeeAssignment.user,
    jobTitle: EMPLOYEE_TITLES[employeeAssignment?.employeeAssignment?.assignmentType],
  };

  const { isPreQualified, wantsToBeCalled } = currentStepData?.preQualification?.preQualificationResult || {};

  const fullStoryEventMetadata = {
    flowType: 'Setup',
    flowSubType: 'Onboarding',
    stepEnum: 'SelfServeModal',
    stepDisplayName: 'Self Serve Modal',
  };

  const handleTalkToSomeone = async () => {
    trackFullStoryEvent({
      eventName: 'self-serve-talk-to-someone',
      metadata: fullStoryEventMetadata,
    });

    await dispatch(trackSelfServeDecisionTaken(signUpFlow?.id, 'TalkToSomeoneFirst'));

    onTalkToSomeone();
  };

  async function handleStartAgreementFlow() {
    trackFullStoryEvent({
      eventName: 'self-serve-continue',
      metadata: fullStoryEventMetadata,
    });

    let flow;
    try {
      await dispatch(trackSelfServeDecisionTaken(signUpFlow?.id, 'GetStarted'));

      flow = await dispatch(generateAgreement());
    } catch (err) {
      console.error(err);
    }
    const [planStep] = flow?.steps?.filter((s) => s.stepName === AgreementFlowStepNames.Plan);

    history.push(`${BASE_PATHS.AGREEMENT_FLOW}/plans/${flow.id}/${planStep?.id}`);
  }

  return (
    <ModalV2
      name={ANALYTICS_MODAL_NAMES.SELF_SERVE}
      noFullPageHeader
      {...rest}
      closeButton={false}
      type={MODAL_TYPES.FULL_PAGE}
    >
      <Confetti />
      <div className={cx('content-wrapper')}>
        <TextBubbleWithUserProfile
          employee={employee}
          text={getSelfServeModalTitle({ isPreQualified, wantsToBeCalled, user })}
        />
        <div className={cx('get-started-wrapper')}>
          <Button
            action={ANALYTICS_CLICK_ACTIONS.SELF_SERVE}
            onClick={handleStartAgreementFlow}
            label={HOMEOWNER_ONBOARDING_STRINGS['self-serve-modal.get-started']}
            className={cx('get-started')}
            size={BUTTON_SIZES.FLUID_LONG_RESPONSIVE}
          />
        </div>
        {!(isPreQualified && wantsToBeCalled) && (
          <div className={cx('talk-first-wrapper')}>
            <Button
              action={ANALYTICS_CLICK_ACTIONS.NON_SELF_SERVE}
              onClick={handleTalkToSomeone}
              buttonType={BUTTON_TYPES.TEXT}
              label={HOMEOWNER_ONBOARDING_STRINGS['self-serve-modal.talk-first']}
              className={cx('talk-first')}
            />
          </div>
        )}
      </div>
    </ModalV2>
  );
};

SelfServeModal.propTypes = {
  onboardingProperties: PropTypes.arrayOf(PropTypes.object).isRequired,
  onTalkToSomeone: PropTypes.func.isRequired,
  user: PropTypes.object,
};

SelfServeModal.defaultProps = {
  user: null,
};

export default SelfServeModal;
